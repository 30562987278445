import React from 'react';
import { Box, Image, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';

import LocationArrowIcon from '../icons/location-arrow.svg';

const SignUpConfirmationPage = props => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="SIGN UP" keywords={['weboxit', 'boxit']} />
      <Header title={t('sign_up.title')} subtitle={t('sign_up.subtitle')} />
      <Container>
        <Box pt="70px" width="74px" mx="auto">
          <Image mx="auto" src={LocationArrowIcon} />{' '}
        </Box>
        <Text
          pt="43px"
          fontSize="35px"
          textAlign="center"
          color="blues.peacock"
        >
          {t('sign_up.confirmation.title')}
        </Text>
        <Text py="56px" fontSize="20px" textAlign="center" color="marine">
          {t('sign_up.confirmation.subtitle')}
        </Text>
      </Container>
    </Layout>
  );
};

export default SignUpConfirmationPage;
